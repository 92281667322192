#InformationalsMessageContainer {
  position: fixed;
  top: 2em;
  height: auto;
  text-align: center;
  left: 2em;
  right: 2em;
  z-index: 3001;

  .alert {
    max-width: 700px;
    margin: 1em auto;
    -webkit-box-shadow: 0 8px 8px rgba(0, 0, 0, .5);
    -moz-box-shadow: 0 8px 8px rgba(0, 0, 0, .5);
    box-shadow: 0 8px 8px rgba(0, 0, 0, .5);
    position: relative;
    strong{
      display: block;
    }
  }
}
#BackgroundProcessMessageContainer {
  position: fixed;
  top: 2em;
  height: auto;
  width: 200px;
  right: 2em;
  z-index: 3000;
}

.yesNoMessage {
  text-align: left;
  .btnContainer {
    float: right;
    padding: 3px;
    button.btn {
      margin-left: 0.5em;
    }
  }
}

.backgroundProcess {
  border-radius: 5px;
  padding: 5px;
  margin: .5em 0;
  box-shadow: 0 2px 4px rgba(0,0,0,.25);
  border: 1px solid black;
  -webkit-transition: background-color 1s;
  -moz-transition: background-color 1s;
  -ms-transition: background-color 1s;
  -o-transition: background-color 1s;
  transition: background-color 1s;

  p {
    text-align: left;
    margin: 0;
  }

  i {
    float: right;
  }

  &.loading {
    background-color: rgba(240,240,240,.8);
  }
  &.done {
    background-color: rgba(200, 255, 200, .9);
  }
}